console.group("inicializando o pack css do app")

import './application.scss'
import './overrides'
import './recyclable'
import './components/main'
import './components/cases'
import './components/registrations'

console.groupEnd();
